import { Icon } from "@chakra-ui/icon";

export const Text = (props) => {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.615 6.615">
        <path
          fill="currentColor"
          d="M4.1.528L1.72.53a.4.4 0 00-.398.396V5.69a.4.4 0 00.398.397h3.174a.398.398 0 00.397-.397c-.006-1.305.01-2.623-.006-3.923l-.067-.165A30.763 30.763 0 004.22.605.132.132 0 004.1.528zM1.72.796h2.248v.66a.4.4 0 00.397.398h.662V5.69a.131.131 0 01-.133.133H1.72a.131.131 0 01-.132-.133V.926a.13.13 0 01.132-.13zM4.232.98l.607.607h-.474a.131.131 0 01-.133-.133zm.147 1.402a.13.13 0 00-.014 0H2.236c-.162.02-.151.258.012.264h2.117c.181.016.197-.26.014-.264zm.266.793H1.97c-.163.02-.15.26.013.264H4.63c.182.016.197-.26.015-.264zm-2.397.794c-.176.001-.176.264 0 .265h2.117c.175-.001.175-.264 0-.265zm2.264.794a.13.13 0 00-.014 0H2.103c-.162.02-.15.26.014.264h2.38c.182.016.197-.26.015-.264z"
        ></path>
      </svg>
    </Icon>
  );
};
